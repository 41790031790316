import React from 'react'
import {navigateTo} from 'gatsby-link'
import Gallery from 'react-photo-gallery'

import Layout from '../components/layout'
import JumboBackground from '../components/jumbo-background'
import CardBox from '../components/card-box'
import Card from '../components/card'
import Button from '../components/button'

import Background from '../images/eskdalerd/8.jpg'

const photos = [
  {
    src: require('../images/eskdalerd/4.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/eskdalerd/5.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/eskdalerd/6.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/eskdalerd/7.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/eskdalerd/8.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/eskdalerd/9.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/eskdalerd/10.jpg'),
    width: 1920,
    height: 1280
  },
  {
    src: require('../images/eskdalerd/11.jpg'),
    width: 1920,
    height: 1280
  }
]

const EskdaleRoad = ({location}) => {
  return(
    <Layout path={location.pathname}>
      <JumboBackground
        background={Background}
      />
      <CardBox title="Eskdale Road, Caulfield North" noSpacer center>
        <Card>
          <Gallery photos={photos}/>
          <br/>
          <Button dark onClick={() => navigateTo('/projects')}>Back to projects</Button>
        </Card>
      </CardBox>
    </Layout>
  )
}

export default EskdaleRoad
